import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Page, Metadata } from '~/components/Layout'
import ReferPage from '~/components/Refer/Page'

const Advocate = ({ data: { page } }) => {

  const { metadata } = page
  const metaTitle = metadata?.title
  const metaDescription = metadata?.description?.description
  const metaImage = metadata?.image?.file?.url

  useEffect(() => {
    if (window._talkableq) {
      window._talkableq.push(['register_affiliate', {}])
    }
  }, [])

  return (
    <Page>
      <Metadata
        title={metaTitle || page.name}
        description={metaDescription}
        image={metaImage}
      />
      <ReferPage />
    </Page>
  )
}

export default Advocate

export const query = graphql`
  query AdvocatePage($locale: String) {
    page: contentfulPage(
      slug: { eq: "advocate" }
      node_locale: { eq: $locale }
    ) {
      ...PageFragment
    }
  }
`

Advocate.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      name: PropTypes.string
    })
  })
}
