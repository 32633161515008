import React from 'react'
import { Container } from 'theme-ui'

const ReferPage = () => (
  <Container>
    <h1 hidden>Refer a friend</h1>
    <div id="talkable-offer"></div>
  </Container>
)

export default ReferPage
